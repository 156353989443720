<template>
    <uw-content title="角色管理" padding="10px">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.drawerCreate.Open()">新的角色</el-button>
        </template>

        <!-- 表格 -->
        <uw-table :show-header="false" :show-footer="false">

            <!-- 数据 -->
            <template slot="data">
                <vxe-table ref="xTable" height="100%" :data="table">

                    <!-- 角色名称 -->
                    <vxe-column min-width="150" field="name" title="角色名称"></vxe-column>

                    <!-- 管理 -->
                    <vxe-column width="120" title="管理" align="right">
                        <template #default="{ row }">
                            <el-link class="w-margin-l-10" type="primary" @click="$refs.drawerUpdate.Open(row.id)">编辑</el-link>
                            <el-link class="w-margin-l-10" type="danger" @click="TableDelete(row.id)">删除</el-link>
                        </template>
                    </vxe-column>
                </vxe-table>
            </template>

        </uw-table>

        <users-role-create ref="drawerCreate" @onChange="TableSearch" />
        <users-role-update ref="drawerUpdate" @onChange="TableSearch" />
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            loading: false,

            table: [],
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.$http.post('/9api/users-role/search-all').then(rps => {
                this.table = rps.data
            })
        },

        TableDelete (id) {
            this.$http.post('/9api/users-role/delete', { id: id }).then(rps => {
                this.TableSearch()
            })
        },
    },
}
</script>